/* @import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap') */
@font-face {
  font-family: "Tenor Sans";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("//www.lake-art.com/cdn/fonts/tenor_sans/tenorsans_n4.ef7b684f1cfa0d3a90338d1fa5f36acdf7889632.woff2?h1=bGFrZWFydGxsYy5hY2NvdW50Lm15c2hvcGlmeS5jb20&h2=bGFrZS1hcnQuY29t&hmac=cef0a3998340c7da58746516b137c3f6db44cd72a383e58729a2d8fd762be397")
      format("woff2"),
    url("//www.lake-art.com/cdn/fonts/tenor_sans/tenorsans_n4.514efc84ed8364b455bb5dd12e65583fe93ccf0f.woff?h1=bGFrZWFydGxsYy5hY2NvdW50Lm15c2hvcGlmeS5jb20&h2=bGFrZS1hcnQuY29t&hmac=31c303897e67ae98644f535b9153e1e4cdbaec38170b0558baafb259e75350a8")
      format("woff");
}

@font-face {
  font-family: "Twentieth Century";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("//www.lake-art.com/cdn/fonts/twentieth_century/twentiethcentury_n4.1a7b2b68023505e1927302cc60a155187098ca59.woff2?h1=bGFrZWFydGxsYy5hY2NvdW50Lm15c2hvcGlmeS5jb20&h2=bGFrZS1hcnQuY29t&hmac=e2741dd320a6a1a5b93a4eb9d92113677853e422e31275b929e3062beaefe2c6")
      format("woff2"),
    url("//www.lake-art.com/cdn/fonts/twentieth_century/twentiethcentury_n4.2bd9ba3193feaaeabefe6059cf0ba7e11c41e810.woff?h1=bGFrZWFydGxsYy5hY2NvdW50Lm15c2hvcGlmeS5jb20&h2=bGFrZS1hcnQuY29t&hmac=9b0df46c00d3fd3fc9eb819e64a578370534627fd3525ee3f73ba83d22f41db5")
      format("woff");
}

@font-face {
  font-family: "Twentieth Century";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("//www.lake-art.com/cdn/fonts/twentieth_century/twentiethcentury_n6.0d3b76303d4b4a3612edbc69654ac2a8ca6886a4.woff2?h1=bGFrZWFydGxsYy5hY2NvdW50Lm15c2hvcGlmeS5jb20&h2=bGFrZS1hcnQuY29t&hmac=c4e7a4cbba8d3fb0647b1e383dedd907a224d764f719323bf9c92f087fb45f4a")
      format("woff2"),
    url("//www.lake-art.com/cdn/fonts/twentieth_century/twentiethcentury_n6.c396df293741c34c7c780d8677bc681d3c3ecd42.woff?h1=bGFrZWFydGxsYy5hY2NvdW50Lm15c2hvcGlmeS5jb20&h2=bGFrZS1hcnQuY29t&hmac=f5e151c60a0a4675e402029004a14f42c28eed6237a08754cb854ff97f86d4aa")
      format("woff");
}

body {
  background: #fff !important;
}

* {
  font-family: Montserrat !important;
}
.page-width {
  max-width: 1500px !important;
  margin: 0 auto;
  padding: 0px 50px !important;
}
/* .page-width.page-width--narrow {
  max-width: 1000px !important;
} */
.mian-four-outer {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-top: 60px;
}
.supernova {
  background-image: none;
  background: #f6f6f6;
}
.first-one h2 {
  color: #fff;
  padding: 15px 0;
  margin-top: 0;
  font-size: 23px;
  margin-bottom: 20px;
  font-weight: 700;
  opacity: 0.8;
  border-bottom: 5px solid #fff !important;
  text-align: center;
}
.first-one p {
  color: #fff;
  text-align: center;
  font-size: 18px;
}
h2.serch-heading {
  font-size: 36px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}
.main-serch-outer {
  padding: 60px 0px 45px 0px;
  background: #4d6556;
}
.main-serch .search__input {
  height: 48px;
  border: 1px solid #000;
}
.short-page-width {
  max-width: 850px;
  margin: 0 auto;
  padding: 0px 50px;
}
/* .serch-one h1 {
    font-size: 150px;
    position: absolute;
    color: #047c4a;
    opacity: 0.1;
    line-height: 1;
    font-weight: 700;
} */
.first-one h3 {
  font-size: 150px;
  position: absolute;
  color: #fff;
  opacity: 0.1;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  width: 100%;
}
.first-one {
  position: relative;
  width: 25%;
}
a.map-section__link {
  font-size: 0;
}
.serch-one h2 {
  font-size: 28px;
  color: #262424;
  margin-bottom: 15px;
  font-weight: 400;
  margin-top: 65px;
}
.serch-one p {
  font-size: 24px;
  margin-top: 0;
  line-height: normal;
  font-weight: 300;
  font-family: s !important;
}
.short-page-width .mian-four-outer {
  margin-top: 35px;
}
h2.section-header__title {
  font-size: 30px;
  font-weight: 400;
  text-align: center;
}
.grid-product__title.grid-product__title--heading {
  font-size: 16px;
  font-weight: 100;
  font-family: sans-serif !important;
  text-align: center;
  letter-spacing: 0.2em;
}

.grid-product__price {
  text-align: center;
  display: none;
}
.toolbar.small--hide {
  display: none;
}
.results__group-2 .grid {
  min-height: 100px;
}
span.testimonial-stars {
  color: rgb(255, 221, 57);
}
.rte-setting.text-spacing p {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.45;
  color: #000;
  font-family: sans-serif;
  letter-spacing: 0.1px;
  text-align: left;
}
blockquote.testimonials-slider__text cite {
  color: #000;
  display: block;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
}
.template-product h3.section-header__title {
  text-align: center;
  font-size: calc(var(--typeHeaderSize) * 0.65);
  color: #000000;
  font-weight: 400;
  text-transform: uppercase;
}
.site-footer .grid__item.footer__item--footer-0 {
  display: none;
}
.site-footer .footer__clear.small--hide {
  display: none;
}
.grid-product__title {
  text-transform: uppercase;
  font-size: calc(var(--typeBaseSize) - 2px);
  letter-spacing: 0.2em;
  text-align: center;
}
.team-list-outer {
  display: flex;
  flex-wrap: wrap;
}
.team-size {
  width: 25%;
  padding: 0px 15px 10px 15px;
}
/* .team-size img {
  filter: grayscale(1);
} */
/* .team-size img:hover {
    border: 1px dashed black;
    border-radius: 10px;
    padding: 10px;
} */
.team-size img {
  /* filter: grayscale(1); */
  border-radius: 10px;
  border: 1px dashed #000;
  transition: 0.7s;
  /* padding: 10px; */
}
.main-outer-team {
  padding-top: 20px;
}
.leftimg_one h2 {
  color: #262424;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 30px;
  text-align: center;
  font-size: 32px;
  color: #262424;
}
.leftimg_one p {
  color: #262424;
  margin-bottom: 30px;
  max-width: 600px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  color: #262424;
}
.team-size h2 {
  margin-top: 20px !important;
  margin: 0;
  font-weight: 700;
  text-align: center;
  font-size: 28px;
  color: #262424;
}
.team-size p {
  opacity: 0.65;
  text-align: center;
  line-height: 1.5;
  color: #262424;
}
.page-width--narrow h2 {
  font-size: 30px;
  color: #000000;
  font-weight: 400;
}
.page-width--narrow p {
  margin-bottom: 10px;
  font-size: 18px;
  color: #262424;
}

.buttton-submit {
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-all {
  border: 1px solid #210021;
  border-radius: 10px;
}
.site-nav__link {
  font-family: Montserrat !important;
}
.shopify-policy__title h1 {
  font-weight: 400;
  font-size: 32px;
  text-transform: uppercase;
  margin-top: 30px;
}
.shopify-policy__body p {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 400;
}

.abc h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.abc p {
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 25px;
}
h1.section-header__title {
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
  text-align: center;
}
.abc svg {
  display: none;
}
.hero__text-shadow {
  bottom: 120px;
}
.collection-hero__content {
  padding: 150px 0 !important;
}
.section-header {
  text-align: center;
}
.collection-item:not(.collection-item--below):after {
  background: transparent;
}
/* .collection-item__title {
  position: static;
  margin-top: 20px;
  text-align: left;
  transform: translateY(0px) !important;
} */
.collection-item__title--overlaid,
.collection-item__title--overlaid-box {
  text-align: center;
}
.collection-item__title span {
  color: #000;
  font-family: Montserrat !important;
  font-size: 24px !important;
  text-shadow: none;
  font-weight: 400 !important;
  /* text-shadow: 5px 3px  8px #000; */
}
body.template-list-collections .slideshow-wrapper {
  max-width: 1500px !important;
  margin: 0 auto;
  padding: 0 50px !important;
}
.predictive_custom_search {
  align-items: center;
  display: block !important;
  margin: 0 auto;
  max-width: 960px;
  position: relative;
  width: 100%;
}

.s-active .predictive__screen {
  display: block;
  z-index: 3;
}

.predictive__screen {
  background: var(--colorModalBg);
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}

.predictive_custom_search form {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  z-index: 4;
}

.search__input-wrap {
  flex: 5;
  position: relative;
  transition: flex 0.3s ease-in-out;
}

.predictive_custom_search.is-active .btn--close-search {
  align-items: center;
  display: flex;
  justify-content: center;
}

.btn--close-search {
  display: none;
  flex: 31px 0 0;
  margin-left: 10px;
}
/* button.collapsible-trigger span {
  font-family: Twentieth Century !important;
} */
body.template-page.customer-service-impluse.page .page-width--narrow h2 {
  font-size: 30px;
  color: #000;
  font-weight: 400;
  text-align: center;
}
.collapsible-content__inner.collapsible-content__inner--faq.rte p {
  font-size: 18px;
  margin: 0;
  padding-left: 29px;
}
.collapsible-content__inner.collapsible-content__inner--faq.rte ul {
  margin-bottom: 0;
}
.collapsible-content__inner.collapsible-content__inner--faq.rte ul li strong {
  font-family: "Tenor Sans" !important;
}
.contact-fire-amp-pine .elementor-social-icons-wrapper.elementor-grid span {
  display: block;
}
.contact-fire-amp-pine h1.section-header__title {
  display: none;
}

.contact-fire-amp-pine h2 {
  font-weight: 600 !important;
}
.contact-fire-amp-pine .jet-instagram-gallery__inner {
  display: none;
}
.contact-fire-amp-pine div#field_1_7 {
  display: none;
}
.contact-fire-amp-pine fieldset {
  border: none;
  padding: 0;
}
/* .contact-fire-amp-pine .uael-gf-style.uael-gf-check-style.elementor-clickable {
    display: none;
}
.contact-fire-amp-pine 
 .index-section.asdfdfsfdf {
    max-width: 1000px;
    text-align: center;
    margin: auto;
} */

#stockist-widget .stockist-icon-marker:before {
  content: "" !important;
  background-image: url(https://cdn.shopify.com/s/files/1/0058/6314/5572/files/location-svgrepo-com.svg?v=1714646089);
  height: 25px;
  width: 27px;
  position: absolute;
  background-size: 100%;
  background-repeat: no-repeat;
}

#stockist-widget .stockist-icon-search:before {
  content: "" !important;
  background-image: url(https://cdn.shopify.com/s/files/1/0058/6314/5572/files/search-alt-svgrepo-com.svg?v=1714646089);
  height: 20px;
  width: 20px;
  position: absolute;
  background-size: contain;
  top: -15px;
  left: -8px;
}
input#findOrder {
  margin-top: 20px;
}
.template-page.customer-service main#MainContent h2,
h1 {
  font-weight: 700 !important;
}
.template-page.customer-service main#MainContent a {
  color: #105541;
  margin-bottom: 10px;
}
.customer-service main#MainContent .page-width--narrow p {
  margin-bottom: 10px !important;
}
.customer-service main#MainContent {
  margin: 20px 0 50px;
}
h2.map-text {
  font-weight: 600;
  font-size: 24px;
  text-transform: uppercase;
}
p.footer__small-text {
  display: none;
}
.contact-fire-pine-impulse .theme-block {
  margin-bottom: 15px;
}
.contact-fire-pine-impulse .page-width--narrow p {
  font-family: auto;
}
.supernova .form-all,
.form-all {
  background-color: #ffffff;
  border: 1px solid #ccc !important;
}
.supernova {
  background-color: #ffffff !important;
}
.customer-service-impluse .theme-block {
  margin-bottom: 5px;
}
.customer-service-impluse .theme-block h5 {
  margin-bottom: -5px;
  margin-top: 31px;
  letter-spacing: normal;
  font-size: 24px;
  text-transform: none;
}
body.template-page.reviews-impulse.page h1.section-header__title {
  display: none;
}
body.template-page.wholesale-impluse.page h1.section-header__title {
  display: none;
}
body.template-page.sustainability-impulse.page header.section-header {
  display: none;
}
.banner-inner {
  height: 650px;
  width: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.4);
}
.banner-inner p {
  max-width: 726px;
  font-size: 16px;
  color: #fff;
}
.banner-inner h2 {
  max-width: 660px;
  text-align: center;
  font-size: 38px;
  color: #fff;
}
.main-outer-row {
  display: flex;
  align-items: center;
  background: #cfc1ad;
}
.text-side {
  width: 60%;
  padding: 40px;
  text-align: center;
}
.image-side {
  width: 40%;
}
.main-outer-row.black {
  background: #000;
}
.main-outer-row.black .text-side {
  color: #fff;
}
.text-side a {
  background: #105540;
}
.enlarge-text h3 {
  margin: 0;
}
body.template-page.sustainability-impulse.page .enlarge-text h3 {
  font-size: 20px;
  line-height: 25px;
}
@media screen and (max-width: 767px) {
  body.template-list-collections .slideshow-wrapper {
    padding: 0 !important;
  }
  .multi-forms {
    display: block !important;
    width: 100%;
  }

  .first-form {
    width: 100% !important;
  }
  .lake-outer-serch {
    display: block;
  }
  .Inquiries-main-outer {
    flex-direction: column;
  }
  .inquire-left,
  .inquire-right {
    width: 100% !important;
  }
  .inquire-left,
  .inquire-right p {
    text-align: center !important;
  }
  a.mobile-nav__link {
    font-size: 1.4em;
  }
  .feature-row__item.feature-row__images.feature-row__item--overlap-images.aos-init.aos-animate {
    padding-left: 0;
    padding-right: 0;
  }
  .feature-row__item.feature-row__text.feature-row__text--right.text-center.aos-init.aos-animate {
    padding-left: 0;
    padding-right: 0;
  }
  .lake-outer-serch .first-one {
    width: 100%;
  }
  .lake-outer-serch .first-one h3 {
    text-align: left;
  }
  .lake-outer-serch .first-one h2 {
    text-align: left;
  }
  .lake-outer-serch .first-one p {
    text-align: left;
  }
  h2.serch-heading {
    font-size: 30px;
  }
  .page-width.page-width--flush-small.featured-colletion {
    padding: 0 !important;
  }
  .short-page-width {
    padding: 0 25px;
  }
  #predictive_search_text form {
    margin: 0;
  }
  .mian-four-outer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .first-one {
    width: 48%;
  }
  .team-size {
    width: 100%;
    padding: 0;
  }
  .first-one h2 br {
    display: none;
  }
  .first-one h2 {
    font-size: 18px;
  }
  /* .main-outer-row {
    flex-direction: column;
  } */
  .image-side {
    width: 100%;
  }
  .image-side img {
    width: 100%;
  }
  .text-side {
    width: 100%;
    padding: 20px;
  }
  .text-side h2 {
    font-size: 24px;
    line-height: 29px;
  }
  .banner-inner p {
    padding: 0px 22px;
    text-align: center;
  }
  .page-width {
    padding: 0 25px !important;
  }
  .enlarge-text p {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .banner-inner h2 {
    font-size: 24px;
    padding: 0px 25px;
    line-height: 27px;
  }
}

.variant-input .product__chip-crossed {
  display: none;
}

.product__color-swatch.disabled_variant::before {
  border-color: var(--color-text);
  box-shadow: 0 0 0 1px var(--color-background);
}
.variant-input.disabled_variant {
  position: relative;
  /* background-color: rgba(247, 247, 247, 0.6); */
  border-color: transparent;
  color: rgba(48, 48, 48, 0.4);
}
.variant-input.disabled_variant.selected {
  border-color: var(--color-text);
}
.variant-input.disabled_variant .product__chip-crossed {
  display: block;
  position: absolute;
  top: -1px;
  bottom: 4px;
  max-width: 8px;
  /* margin-bottom: 20px; */
  /* margin-left: 20px; */
  left: 16px;
  height: 41px;
  transform: rotate(23deg);
}

.btn.btn--text-link,
.spr-summary-actions-newreview.btn--text-link {
  padding: 0;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  text-underline-offset: 0.2em;
  text-decoration-thickness: 1px;
  text-transform: none;
  transition: opacity 0.2s ease-in-out;
  min-height: 0;
  min-height: initial;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  word-break: break-word;
  background-color: transparent;
  color: var(--colorTextBody);
  font-weight: var(--typeBaseWeight);
  font-size: var(--typeBaseSize);
  padding-bottom: 8px;
}
.btn.btn--text-link:hover {
  background-color: transparent !important;
}
.btn.btn--text-link:active {
  background-color: transparent !important;
}

.cart__footer-order-note-trigger-icons .icon {
  width: 10px;
  height: 10px;
  margin-right: 8px;
  margin-right: calc(4px * 2);
  vertical-align: unset;
}

.cart__footer-order-note-input {
  padding-top: 20px;
  padding-bottom: 20px;
}

.cart__footer-order-note-trigger-icons .icon.hidden {
  display: none;
}

.product-options-errors {
  background-color: #8b0000;
  color: #fff;
  padding: 10px;
  text-align: center;
  margin-top: 20px;
}

body.template-index.engraved-art-fire-amp-pine-bluffton-sc.index
  div#search_index {
  display: none;
}
body.template-page.damage-claim-impulse.page .theme-block {
  margin-bottom: 0;
}

span.click_heree {
  background-color: #000;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}

.theme-block h2 {
  font-weight: 700 !important;
  margin-top: 0 !important;
  margin-bottom: 30px !important;
  text-align: center !important;
  font-size: 32px !important;
  color: #262424 !important;
}

.main-content {
  /* min-height: 300px; */
  min-height: 250px;
}

.shopify-policy__container {
  max-width: 1500px !important;
  margin: 0 auto;
  padding: 0 50px !important;
}
div#shopify-section-template--15355302936694__rich_text_pL4AXq {
  margin: 10px !important;
}
h2.hotspots__title.h2.text-left {
  text-transform: uppercase;
  font-weight: 500 !important;
  padding: 0;
}
div#search_index {
  display: none;
}
.rte-setting.text-spacing + a {
  background: #28442f;
  color: #fff;
}
section#shopify-section-sections--15380846575734__171646889392aad326
  .index-section {
  margin: 0 !important;
}
.main-outer-row.page-width {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  background: #4d6556;
}
.main-outer-row.page-width .text-side h2 {
  text-align: left;
  color: #fff;
}
.main-outer-row.page-width .text-side p {
  text-align: left;
  color: #fff;
}
.main-outer-row.page-width .text-side a {
  text-align: left;
  color: #fff;
  border: 1px solid #fff;
  background: #4d6556;
}
.breand-images img {
  height: 150px;
  width: 150px;
  object-fit: contain;
}
.breand-images {
  display: flex;
  align-items: center;
  justify-content: center;
}
h1.h2.product-single__title {
  color: #28442f;
}
.variant-wrapper.js {
  margin-top: 25px;
}
.breand-outer.page-width {
  margin-top: 65px;
}
.enlarge-text h3 {
  font-size: 25px;
  font-weight: 700;
  line-height: 38px;
}
.grid-product__meta {
  line-height: var(--typeBaselineHeightMinus01);
  padding: 10px 0 6px;
  /* position: absolute; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  color: #000;
  /* text-shadow: 0px 0px 5px #000; */
  width: 100%;
}
#SiteHeader {
  border-bottom: 1px solid #e8e8e1;
}

.shopify-section-group-header-group + .shopify-section-group-popup-group {
  display: none;
}
.site-footer__linklist a {
  font-size: 18px;
}
.footer__collapsible p {
  font-size: 18px;
}
h2.h4.footer__title {
  font-size: 18px;
  letter-spacing: 1px;
}
span.collection-item__title.collection-item__title--below.collection-item__title--heading.collection-item__title--left {
  text-align: center;
}
.Inquiries-main-outer {
  display: flex;
  justify-content: space-between;
}
.Inquiries-main-outer h5 {
  margin-bottom: -5px;
  margin-top: 31px;
  letter-spacing: normal;
  font-size: 24px;
  text-transform: none;
}
.inq h2 {
  text-align: center;
}

.inquire-left,
.inquire-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.testimonials-section.testimonials-template--15383813324918__testimonials_FwRrMq.testimonials-section--with-divider.text-center.aos-init.aos-animate {
  border-bottom: 1px solid #e8e8e1;
}

.testimonials-slide .testimonials-slider__text {
  transform: scale(0.86);
}
.testimonials-slide.is-selected .testimonials-slider__text {
  border: 1px solid #ddd;
}
div#CustomerLoginForm {
  max-width: 485px;
  width: 100%;
  padding: 40px;
  border: 4px solid #fff;
}
.mian-outer-portal {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  background: #28442f4d;
  padding: 80px;
  margin-bottom: 30px;
}
h2.portal-heading {
  text-align: center;
  font-size: 36px;
  margin-bottom: 28px;
  font-weight: 500;
}
.frame-side {
  /* background: #fff; */
  /* padding: 40px; */
  /* box-shadow: #00000026 0 2px 10px; */
  width: 500px;
  height: 509px;
}
.multi-forms {
  display: flex;
  width: 100%;
}

.first-form {
  width: 50%;
}
.inquiry-form {
  width: 100%;
}
.tc-mode-images .custom-tool {
    padding: 0 !important;
}
/* .tc-image {
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: auto;
    padding: 0;
    pointer-events: none!important;
    height: auto;
    max-width: 100%;
} */

.cart__item-sub{
  align-items: center !important;
  flex: 1 1 30% !important;
}
